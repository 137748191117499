<template>
  <v-container>
    <div class="">
      <h1 v-html="$t('search_mhw_table')"></h1>
      <v-row class="row search spacer-md">
        <v-col xs="12" sm="6" md="3">
          <v-form ref="searchform">
            <search-mhw ref="search-mhw" @search="search" />
          </v-form>
        </v-col>
      </v-row>
      <v-row class="d-print-none">
        <v-col>
          <v-btn color="primary" @click="search" :disabled="!hasPostcodeCluster()"><span v-html="$t('search_mhw_search')" /></v-btn>
          <v-btn color="secondary" @click="reset"><span v-html="$t('search_mhw_reset')" /></v-btn>
          <v-btn color="secondary" @click="printPage()" :disabled="!hasPostcodeCluster()"><span v-html="$t('search_mhw_print')" /></v-btn>
        </v-col>
        <v-col v-if="$privileges.has({ path: '/postcodeClusters', permission: 'read' })">
          <v-btn color="secondary" @click="download" :loading="loading_download"><span v-html="$t('search_mhw_download')" /></v-btn>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-data-table-server
        :headers="fields"
        :items="items"
        class="zero-width d-print-table"
        :fields="fields"
        :loading="loading"
        :items-length="totalElements"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
        density="compact"
      >
        <template v-slot:[`item.sampleDate`]="{ item }">
          {{ $moment(item.sampleDate).format('DD.MM.YYYY') }}
        </template>
        <template v-slot:[`item.average`]="{ item }">
          {{ $formatNumber(item.average, 0) }}
        </template>
        <template v-slot:[`item.standardDeviation`]="{ item }">
          {{ $formatNumber(item.standardDeviation, 2) }}
        </template>
        <template v-slot:[`item.measurementDeviation`]="{ item }">
          {{ $formatNumber(item.measurementDeviation, 2) }}
        </template>
      </v-data-table-server>
    </div>
    <v-row v-if="hasPostcodeCluster()">
      <v-col sm="12" lg="6" xl="4">
        <v-table>
          <tr>
            <td><span v-html="$t('mhw_postcodecluster_codes')"></span></td>
            <td>{{ SearchMilkQualityBenchmarkStore.postcodeCluster.codes }}</td>
          </tr>
          <tr>
            <td><span v-html="$t('mhw_postcodecluster_farmcount')"></span></td>
            <td>
              {{ SearchMilkQualityBenchmarkStore.postcodeCluster.farmCount }}
            </td>
          </tr>
        </v-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6" md="6">
        <p v-html="$t('search_mhw_bottom_text')"></p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { showError, fileCreator } from '@/services/axios'
import _ from 'lodash'
import searchMhw from '@/components/searchCards/searchMilkQualityBenchmark.vue'
import { DTSHeader } from '@/services/BackendService'
import { useSearchMilkQualityBenchmarkStore } from '@/store/SearchMilkQualityBenchmarkStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'milkqualitybenchmarks_read',
  data() {
    return {
      //items: [{ agisId: 1, sampleDate: '12-12-2022', urea: 1, average: 2, standardDeviation: 23, measurementDeviation: 12 }],
      items: [],
      totalElements: 1,
      loading: false,
      loading_download: false,
      fields: <DTSHeader[]>[
        // AGIS-ID Betrieb
        {
          title: this.$t('search_mhw_agisId'),
          key: 'farmAgisId',
          sortable: false,
          align: 'left'
        },
        // Datum
        {
          title: this.$t('search_mhw_sampleDate'),
          key: 'sampleDate'
        },
        // Harnstoff: Betrieb [mg/100ml]
        {
          title: this.$t('search_mhw_urea'),
          value: 'urea',
          align: 'end'
        },
        // Harnstoff: Regionaler Mittelwert [mg/100ml]
        {
          title: this.$t('search_mhw_average'),
          value: 'average',
          align: 'end'
        },
        // Standardabweichung regionaler Mittelwert
        {
          title: this.$t('search_mhw_standardDeviation'),
          value: 'standardDeviation',
          sortable: false,
          align: 'end'
        },
        // Messabweichung Einzelwert
        {
          title: this.$t('search_mhw_measurementDeviation'),
          value: 'measurementDeviation',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  components: {
    searchMhw
  },
  computed: {
    SearchMilkQualityBenchmarkStore() {
      return useSearchMilkQualityBenchmarkStore()
    },
    query() {
      return {
        ...this.getJavaPageOptions({ page: 0, size: 10, sort: ['sampleDate,asc'] }),
        postcodeClusterId: useSearchMilkQualityBenchmarkStore()?.postcodeCluster?.id,
        farmId: useSearchMilkQualityBenchmarkStore().farmId,
        from: useSearchMilkQualityBenchmarkStore().from,
        until: useSearchMilkQualityBenchmarkStore().until
      }
    }
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      if (!this.$refs['searchform'].validate().valid) return
      this.loading = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualityBenchmarks/findSamples', {
          params: this.query
        })
        const items = await response.data.content
        items.forEach((element) => (element.measurementDeviation = element.urea * 0.214))
        this.items = items
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (error) {
        showError(error)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-mhw'].reset()
    },
    async download() {
      try {
        this.loading_download = true
        const response = await this.axios.get(apiURL + '/postcodeClusters/findFarmCount', {
          params: this.query,
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(response, 'postCodeClusters.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loading_download = false
      }
    },
    printPage() {
      window.print()
    },
    hasPostcodeCluster() {
      return this.SearchMilkQualityBenchmarkStore.postcodeCluster && this.SearchMilkQualityBenchmarkStore.postcodeCluster.codes
    }
  },
  watch: {
    'SearchMilkQualityBenchmarkStore.postcodeCluster.codes': {
      handler() {
        this.items = []
      },
      deep: true
    },
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.query.size) this.load()
  }
})
</script>
<style scoped>
@media print {
  /* All your print styles go here */
  .container {
    padding: 0;
  }
}
</style>
